import React from "react"
import Layout from "../../components/layout"
import phun from '../../assets/phun-thumb.jpg'
import analytics from '../../assets/casestudies/phun-analytics.png'
import charts from '../../assets/casestudies/phun-charts.png'
import mapping from '../../assets/casestudies/phun-maps2.jpg'
import mapping2 from '../../assets/casestudies/phun-maps3.jpg'
import CenteredContent from '../../components/centeredContent'
import FullWidthContent from '../../components/fullWidthContent'

const DNB = () => (
  <Layout headerType="phunware" headerText='Phunware'>
    <CenteredContent
			image1={phun} 
			header="Phunware"
			body="At Phunware as a front end software engineer I was responsible for building the front-end of a cloud platform called MaaS from scratch. I worked with JAVA developers to design API’s that I integrated into a PHP application using the Symfony framework. I also worked very closely with the UI/UX and product teams to rapidly build working prototypes of features that eventually shipped to customers."
			rotation="3deg"
		/>
    <FullWidthContent
			bgcolor1={'#dee5fc'}
			image={analytics} 
			header="Admin Dashboards"
			body="Being primarily an admin tool, the MaaS UI consisted mainly of vairous dashboarsd and interfaces for our users to view data about mobile app usage, alerts and indoor mapping"
			rotation="3deg"
      bottomAngleMargin="-60px"
      bgPosition="center right"
		/>
     <CenteredContent
			image1={charts} 
			header="Charts & visualizations"
			body="Working on a new product at a startup requires the ability to iterate quickly on features — often having to wear many hats in the process. I worked closely with engineering, product and design to come up with quick solutions for visualizing and managing mobile app data using front end visualization tools."
			rotation="-3deg"
		/>
     <FullWidthContent
		  bgcolor1={'#dee5fc'}
			image={mapping} 
			header="Indoor mapping UI"
			body="One of the biggest challenges with this project was to design and build a UI to manage points of interest and routes on indoor maps. This would  allow people using our mobile apps to get directions from place-to-place inside buildings. It was amazing to see people place points on a map, then pull out your mobile app to see those points update in real time."
			rotation="-3deg"
      bottomAngleMargin="-60px"
      bgPosition="top right"
		/>
     <CenteredContent
      type="web"
			image1={mapping2} 
			header="Mapping layout tools"
			body=" I used the Leaflet open source mapping framework along with a custom set of drawing tools to allow users to place points, manage metadata and generate routes on indoor maps."
			rotation="3deg"
		/>
		/>
  </Layout>
)

export default DNB
